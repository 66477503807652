import ItemPrice from '../payment/ItemPrice';
import PreviewStore from './tool/PreviewStore';

class Art {
    constructor() {
        this.type = "art";
        this.clearForSync = false;
        this.calc = new ItemPrice();

        this.getLayout = this.getLayout.bind(this);
        this.getItemsToCart = this.getItemsToCart.bind(this);
        this.calcItemPrice = this.calcItemPrice.bind(this);
        this.uuid = this.uuid.bind(this);
        this.getCartResumeItem = this.getCartResumeItem.bind(this);
        this.getDataFromEditor = this.getDataFromEditor.bind(this);
    }

    getLayout(id, size) {
        let layout = {
            id: id.toString(),
            size: size,
            model: "CB" + id + size,
            pieceNumber: window.editorConfig.art.data.layouts[id.toString()].sizes[size].bounds.length,
            sizeComposition: window.editorConfig.art.data.layouts[id.toString()].sizes[size].size_group,
            pieces: []
        };

        for (let i=0; i < window.editorConfig.art.data.layouts[id.toString()].sizes[size].sizes.length; i++) {
            layout.pieces.push({
                id: i+1,
                model: layout.model + (i+1),
                size: window.editorConfig.art.data.layouts[id.toString()].sizes[size].sizes[i]
            });
        }

        return layout;
    }

    getDataFromEditor() {
        let data = null;
        try {
            data = window.Editor.getData();
            if (parseFloat(data.cost.price.total) === 0) {
                window.Editor.updatePrice();
                data = window.Editor.getData();
            }
        } catch(error) {
            console.error(error);
        }

        if (data === null) {
            return;
        }

        let preview = null;
        try {
            preview = window.Editor.getPreview();
        }
        catch(error) {
            console.error(error);
        }

        return {data: data, preview: preview};
    }

    getItemsToCart() {
        let item = this.getDataFromEditor();
        let newData = JSON.parse(JSON.stringify(item.data));
        
        // Ojo al piojo
        newData.material = 'C';
        newData.stand = 'B';

        // limpiando $$hashKey
        if (newData.images !== undefined && newData.images.length) {
            newData.images.forEach(function (item) {
                delete item.data.$$hashKey;
            });
        }

        newData.layout = this.getLayout(newData.layout_id, newData.layout_size);
        newData.info = this.getCartResumeItem(newData, null, undefined);

        const attributes = {
            data: newData
        };

        const id = this.uuid();
        PreviewStore.asyncStore(item.preview, id);
        return [{
            type: this.type,
            price: this.calcItemPrice(newData.cost.price.base),
            quantity: newData.cost.quantity,
            plusValia: true,
            attributes: attributes,
            deleted: 0,
            id: id
        }];
    }

    calcItemPrice(priceBase) {
        return this.calc.calc(priceBase, 1, true, this.type, 1, 1);
    }

    uuid() {
        return ((Math.random()*1e32).toString(36).substring(1,8)).toUpperCase();
    }

    getCartResumeItem(item, price, image) {
        let iResume = {
            model: item.layout.model,
            price: price,
            size: item.layout_size + ' (' + item.layout.sizeComposition.join('x') + ' cm)',
            material: 'Canvas',
            stand: 'Bastidor'
        };

        if (image !== undefined) {
            iResume.image = image.url.replace('/f/f/', '/150/-/');
        }

        let pieces = [];
        for (let j = 0; j < item.layout.pieces.length; j++) {
            pieces.push(item.layout.pieces[j].size.join('x'));
        }
        iResume.pieces = pieces.join(' cm / ') + ' cm';
        return iResume;
    }
}

export default Art;