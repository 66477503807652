class Conectoc {
    constructor() {
        this.type = "conectoc";
        this.clearForSync = true;
        
        this.getItemsToCart = this.getItemsToCart.bind(this);
        this.uuid = this.uuid.bind(this);
        this.getCartResumeItem = this.getCartResumeItem.bind(this);
    }

    getItemsToCart() {

        if (window.FUSIONN.cart !== undefined) {
            window.FUSIONN.cart.items = [];
        }

        const id = this.uuid();
        /*let items = [{
            type: this.type,
            price: {
                taxes: [],
                quantity: 1,
                price: {
                    base: parseFloat(window.form.iShippingBase),
                    unit: parseFloat(window.form.iShippingBase),
                    item: parseFloat(window.form.iShippingBase),
                    discount: parseFloat(window.form.iShippingDiscount),
                    discountItemInfo: { has: parseFloat(window.form.iShippingDiscount) > 0 },
                    taxes: 0,
                    subTotal: window.form.iShippingTotal,
                    total: window.form.iShippingTotal
                },
                currency: window.FUSIONN.config.sitePrices.currency
            },
            quantity: 1,
            plusValia: false,
            attributes: {},
            courier: {...window.form},
            deleted: 0,
            id: id
        }];*/

        let items = [{
            type: this.type,
            price: {
                taxes: [],
                quantity: 1,
                price: {
                    base: parseFloat(window.form.iShippingTotal),
                    unit: parseFloat(window.form.iShippingTotal),
                    item: parseFloat(window.form.iShippingTotal),
                    discount: 0,
                    discountItemInfo: { has: false },
                    taxes: 0,
                    subTotal: window.form.iShippingTotal,
                    total: window.form.iShippingTotal
                },
                currency: window.FUSIONN.config.sitePrices.currency
            },
            quantity: 1,
            plusValia: false,
            attributes: {},
            courier: {...window.form},
            deleted: 0,
            id: id
        }];

        console.log(items);

        return items;
    }


    uuid() {
        return ((Math.random()*1e32).toString(36).substring(1,8)).toUpperCase();
    }

    getCartResumeItem(item, price, image) {
        if (window.form.iHtmlSummary) {
            return {
                type: this.type,
                resume: window.form.iHtmlSummary
            };    
        } else {
            return {
                type: this.type,
                resume: ""
            };    
        }
    }
}

export default Conectoc;