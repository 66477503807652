import React, {Component} from 'react';
import LazyLoad from 'react-lazyload';
import ImageEvent from "../../../client/ImageEvent";

export const masonryOptions = {
    itemSelector: '.movie-poster-grid-item',
    resize: true
};

export default class SimpleGalleryContainerClick extends Component {

    constructor(props) {
        super(props);
        this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
    }

    handleFavoriteClick(e, item) {
        e.preventDefault();
        if (e.target.classList.contains('selected')) {
            window.FUSIONN.removeFavoriteByDataUUID(item.data.uuid);
            e.target.classList.remove('selected');
        } else {
            e.target.classList.add('selected');
            window.FUSIONN.addFavorite(item);
        }
    }

    handleClickImagePoster = (event, photo) => {
        //photo.data.galleryPosition = index;
        window.Editor.setImage(photo.data);
        window.scrollTo(0, 0);

        const imageEvent = new ImageEvent();
        imageEvent.clickEvent(photo.data.imageId, "Poster", "click");
    };


    render() {
        return (
            <div className='movie-poster-container'>
                <h3>{this.props.name}</h3>
                <div className={"movie-poster-grid"} >
                    {this.props.images.map((img, i) => {
                        return (
                            <div className={'movie-poster-grid-item'} key={i}>
                                <LazyLoad offset={500} once={true} placeholder={(<img src={'/im/fotoc/icons/icon-image-load-220-330.png'} />)}>
                                    <a href="#" className="icon">
                                        <i className={"fas fa-heart " + (window.FUSIONN.hasFavoriteByDataUUID(img.data.uuid) ? 'selected' : '') } onClick={(event) => {
                                            this.handleFavoriteClick(event, img);
                                            return false;
                                        }} >&nbsp;</i>
                                    </a>
                                    <a href="#" onClick={(event) => {
                                        this.handleClickImagePoster(event, img);
                                        return false;
                                    }}>
                                        <img src={img.src}/>
                                        <div className={"posterItemInfo"}>
                                            {
                                                img.data.metadata.name ? (
                                                    <div className='poster-swipper-title'>
                                                        {img.data.metadata.name}
                                                    </div>
                                                ) : ''
                                            }
                                            {
                                                img.data.metadata.year ? (
                                                    <div className='poster-swipper-year'>
                                                        ({img.data.metadata.year})
                                                    </div>
                                                ) : ''
                                            }
                                        </div>
                                    </a>
                                </LazyLoad>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}