import React from "react";
import jQuery from 'jquery';
import LazyLoad from 'react-lazyload'
import CropWithUploadCare from '../../../util/UploadCareTools';

const u = jQuery.noConflict();

export default function StockImage(props) {
    const data = props.data;
    const img = data.assets.huge_thumb;
    const handleClickImage = (data) => {
        if (window.Editor !== undefined) {
            u('#editorSection').show();
            window.Editor.setStockImage(data);
            window.document.getElementById("editorSection").scrollIntoView();
        }
    };

    const handleFavoriteClick = (e, item) => {
        e.preventDefault();
        if (e.target.classList.contains('selected')) {
            window.FUSIONN.removeFavoriteByDataUUID(item.data.id);
            e.target.classList.remove('selected');
        } else {
            e.target.classList.add('selected');
            window.FUSIONN.addFavorite(item);
        }
    };

    const w = data.assets.huge_thumb.width;
    const h = data.assets.huge_thumb.height - 20;

    return (
        <LazyLoad 
            height={h} 
            width={w} 
            offset={20}
            once={true} 
            >
            <a href="#" className="icon">
                <i className={"fas fa-heart " + (window.FUSIONN.hasFavoriteByDataUUID(data.id) ? 'selected' : '') } 
                    onClick={(event) => {
                        handleFavoriteClick(event, data);
                        return false;
                    }} 
                >&nbsp;</i>
            </a>
            <img src={CropWithUploadCare(img.url, w, h)} onClick={(event) => {handleClickImage(data)}} />
        </LazyLoad>
    );
};