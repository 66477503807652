import React, { Component } from 'react';
import LazyLoad from 'react-lazyload'
import {Link} from "react-router-dom";
import slugify from "slugify";


export const masonryOptions = {
    itemSelector: '.poster-grid-item',
    resize: true
};

const handleFavoriteClick = (e, item) => {
    e.preventDefault();
    if (e.target.classList.contains('selected')) {
        window.FUSIONN.removeFavoriteByDataUUID(item.data.uuid);
        e.target.classList.remove('selected');
    } else {
        e.target.classList.add('selected');
        window.FUSIONN.addFavorite(item);
    }
};

const items = (img, i) => {
    return (
        <div className={'poster-grid-item'} key={i}>
            <LazyLoad height={330} offset={500} once={true} placeholder={(<img src={'/im/fotoc/icons/icon-image-load-220-330.png'} />)} >
                <a href="#" className="icon">
                    <i className={"fas fa-heart " + (window.FUSIONN.hasFavoriteByDataUUID(img.data.uuid) ? 'selected' : '') } onClick={(event) => {
                        handleFavoriteClick(event, img);
                        return false;
                    }} >&nbsp;</i>
                </a>
                <Link to={"/p/poster/view/poster/" + img.data.imageId + '/' + slugify(img.data.metadata.original_name, {
                    replacement: '-',
                    lower: true,
                    strict: true,
                    locale: 'es'
                })}>
                    <img src={img.src} />
                    <div className={"posterItemInfo"}>
                        {
                            img.data.metadata.name ? (
                                <div className='poster-swipper-title' >
                                    {img.data.metadata.name}
                                </div>
                            ) : ''
                        }
                        {
                            img.data.metadata.year ? (
                                <div className='poster-swipper-year' >
                                    ({img.data.metadata.year})
                                </div>
                            ) : ''
                        }
                    </div>
                </Link>
            </LazyLoad>

        </div>
    );
}

export default class SimpleGalleryContainerLink extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='poster-masonry-container' >
                <h3>{this.props.name}</h3>
                <div className={"poster-grid"} >
                    {this.props.images && Array.isArray(this.props.images) && this.props.images.map(items)}
                    {this.props.images && this.props.images.related && this.props.images.related.map(items)}
                    {this.props.images && this.props.images.similar && this.props.images.similar.map(items)}
                    {this.props.images && this.props.images.categories && this.props.images.categories.map(items)}
                </div>
            </div>
        );
    }
}