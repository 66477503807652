import ConfigSite from "../ConfigSite";
import axios from "axios";

export default class ImageEvent {
    config;
    api;

    constructor() {
        this.config = new ConfigSite();
        this.api = this.config.global.mediaApi.urlBase;
    }

    clickEvent = (imageId, page, type) => {

        let dataToSend = {
            imageId: imageId,
            page: page,
            domain: window.location.hostname,
            url: window.location.pathname,
            type: type,
            search: window.lastTextSearch,
            id_sale: window.FUSIONN.session,
        }
/*
        const api_url = this.api + "images/event";
        let request = async (dataToSend, api_url) => {
            await axios.post(api_url, dataToSend);
        };
        request(dataToSend, api_url);
        */
    };

}
