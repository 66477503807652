import React, {useState} from 'react';

export default function SearchForm()  {    
    let searchParams = new URLSearchParams(window.location.search);
    const [q, setQ] = useState(searchParams.get("q")||"");
    
    const handleSubmit = (evt) => {
        if (q === null || q === "") {
            evt.preventDefault();
            return false;
        }
        return true;
    }

    return (
        <React.Fragment>
            <form action="/p/art/search" method="get" onSubmit={handleSubmit} >
                <input
                    type="text"
                    id="header-search"
                    name="q" 
                    className='search-header-container' 
                    value={q}
                    onChange={e => setQ(e.target.value)}
                />
                <button type="submit"><span className={"icon search"}><i className={"fas fa-search"}></i></span></button>
            </form>
        </React.Fragment>
    );
}
