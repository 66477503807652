import React, { useState, useEffect } from "react";
import {showLoading, hideLoading, gotoTop} from "../../util/SwitchLoading";
import jQuery from 'jquery';
import ConfigSite from "../../ConfigSite";
import SimpleGalleryContainerLink from "../../ui/fanatico/poster/SimpleGalleryContainerLink";
import axios from "axios";
import { useParams } from 'react-router';
const u = jQuery.noConflict();

const config = new ConfigSite();
const API = 'es/cinematheque/collection/';

const hideAll = () => {
    u('#top').hide();
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

export default function CollectionPage() {

    let [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    showLoading();
    hideAll();
    u('body').addClass('home');
    u('body').removeClass('producto');
    u('section.top').show();
    u('#info').show();

    useEffect(() => {
        showLoading();
        setIsLoading(true);
        axios.get(config.global.mediaApi.urlBase + API + params.name)
            .then((response) => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' + response.status);
                    return;
                }
                setIsLoading(false);
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [params.name]);

    if (isLoading) {
        return (<React.Fragment></React.Fragment>);
    }

    gotoTop();
    hideLoading();

    return (
        <section className={"template_align_right"} >
            <SimpleGalleryContainerLink images={data.images} name={data.name} slug={data.slug}/>
        </section>
    );
}
