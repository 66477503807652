import ConfigSite from './ConfigSite';
import CookieUtils from './util/CookieUtils';
import ItemPrice from './payment/ItemPrice';
import Fotoc from './product/Fotoc';
import Tiles from './product/Tiles';
import Multitiles from './product/Multitiles';
import Slimtiles from './product/Slimtiles';
import Poster from './product/Poster';
import Art from './product/Art';
import Conectoc from './product/Conectoc';
import axios from "axios";

/*
import Etiquetoc from './app/product/Etiquetoc.js';
import Tienda from './app/product/Tienda.js';
window.ETIQUETOC = new Etiquetoc();
window.Tienda = new Tienda():
*/

class Fusionn {

    constructor() {
        this.config = new ConfigSite();
        this.cookies = new CookieUtils();
        this.session = this.cookies.getCookie('fusionn');
        this.needUpdateSession = true;
        this.calc = new ItemPrice();
        this.favorites = [];

        this.productType = {
            fotoc: new Fotoc(),
            tiles: new Tiles(),
            multitiles: new Multitiles(),
            slimtiles: new Slimtiles(),
            poster: new Poster(),
            art: new Art(),
            conectoc: new Conectoc(),
            /*
                tienda: new Tienda(),
                etiquetoc: new Etiquetoc;
             */
        };

        this.setup = this.setup.bind(this);
        this.initSession = this.initSession.bind(this);
        this.updateSession = this.updateSession.bind(this);
        this.refreshCart = this.refreshCart.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
        this.getCart = this.getCart.bind(this);
        this.getCartItemCount = this.getCartItemCount.bind(this);
        this.checkCart = this.checkCart.bind(this);
        this.setDeliveryMethod = this.setDeliveryMethod.bind(this);
        this.updateCartTotals = this.updateCartTotals.bind(this);
        this.updateCart = this.updateCart.bind(this);
        this.initCartCurrency = this.initCartCurrency.bind(this);
        this.calcDiscount = this.calcDiscount.bind(this);
        this.getCartResume = this.getCartResume.bind(this);
        this.detectAdAccess = this.detectAdAccess.bind(this);
        this.prepareSiteInitView = this.prepareSiteInitView.bind(this);
        this.addFavorite = this.addFavorite.bind(this);
        this.removeFavoriteByDataUUID = this.removeFavoriteByDataUUID.bind(this);
        this.listFavorites = this.listFavorites.bind(this);
        this.setConfigItem = this.setConfigItem.bind(this);
        this.hasFavoriteByDataUUID = this.hasFavoriteByDataUUID.bind(this);
    }

    setup() {
        this.initSession();
    }

    prepareSiteInitView() {
        window.initCartView();
    }

    initSession() {

        axios.get('/site/session')
            .then((response) => {
                let data = response.data;
                if (data.session !== undefined) {
                    // verifico la data de la sesion, si hay nueva se actualiza localmente
                    const checksum = this.config.getString(data.checksum);
                    if (checksum !== data.checksum) {
                        if (data.session.user !== undefined && data.session.user !== null) {
                            this.user = data.session.user;
                        } else {
                            this.user = {};
                        }
                        this.config.setItem('user', this.user);

                        if (data.session.shipping !== undefined && data.session.shipping !== null) {
                            this.shipping = data.session.shipping;
                        } else {
                            this.shipping = {};
                        }
                        this.config.setItem('shipping', this.shipping);

                        if (data.session.cart !== undefined && !Array.isArray(data.session.cart)) {
                            this.cart = data.session.cart;
                        } else {
                            this.cart = { items: [] };
                        }
                        this.cart.browserInfo = window.browserInfo;
                        this.config.setItem('cart', this.cart);

                        /*
                        if (data.session.favorites !== undefined && Array.isArray(data.session.favorites)) {
                            this.favorites = data.session.favorites;
                        } else {
                            this.favorites = [];
                        }
                        this.config.setItem('favorites', this.favorites);
                        */

                        this.config.setItem('checksum', data.checksum);
                    }

                    this.config.setItem('session', data.session._id);
                    this.session = data.session._id;

                    this.favorites = this.config.getItem('favorites');
                    if (!Array.isArray(this.favorites)) {
                        this.favorites = [];
                    }

                    this.prepareSiteInitView();

                    if (data.reply_to !== undefined) {
                        data.reply_to.forEach(function (element) {
                            fetch('https://' + element + '/site/session', {
                                method: 'post',
                                body: JSON.stringify({ session: data.session._id, action: 'setCookie' }),
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Cache': 'no-cache'
                                },
                                credentials: 'include'
                            }).then(function (response) {
                                return response.json();
                            }).then(function (data) {
                                //console.log(data);
                            });
                        });
                    }
                }
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });

    }

    setConfigItem(element) {
        this.config.setItem(element, this[element]);
    }

    updateSession(element, from) {
        if (!this.needUpdateSession) {
            return;
        }
        let data = {
            key: element,
            value: this[element],
            action: 'update'
        };
        this.setConfigItem(element);

        fetch('/site/session', {
            method: 'post',
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.json();
        }).then(function (data) {
            if (data.status === 'ok' && data.checksum !== '') {
                this.config.setItem('checksum', data.checksum);
            }
        }.bind(this));
    }

    addToCart(type) {
        if (!this.productType[type]) {
            return false;
        }
        this.checkCart();

        if (this.productType[type].clearForSync) {
            this.cart.items = this.cart.items.filter(item => item.type !== type);
        }

        const items = this.productType[type].getItemsToCart();
        if (items !== null && items.length > 0) {
            this.cart.items.push(...items);
            this.updateCart("fusionn.addToCart");
            return true;
        }
        return false;
    }

    removeFromCart(id) {
        this.checkCart();
        this.cart.items = this.cart.items.filter(item => item.id !== id);
        this.updateCart("fusionn.removeFromCart");
    }

    getCart() {
        this.cart = this.config.getItem('cart');
        return this.cart;
    }

    getCartItemCount() {
        let total = 0;
        this.cart.items.forEach((item) => { total += item.attributes.quantity !== undefined ? item.attributes.quantity : 1; });
        return total;
    }

    setFavorites(items) {
        this.favorites = JSON.parse(JSON.stringify(items));
        this.updateSession('favorites', 'fusionn.setFavorites');
    }

    checkCart() {
        let cart = this.getCart();
        if (this.cart === undefined || Array.isArray(this.cart)) {
            this.cart = { items: [] };
        } else {
            this.cart = cart;
        }
    }

    setPromoCode(promoCode) {
        this.checkCart();
        this.cart.promoCode = promoCode;
        this.updateCart("fusionn.setpromocode");
    }

    setDeliveryMethod(id, description, code, amount) {
        if (this.cart.deliveryMethod === undefined || this.cart.deliveryMethod.id !== id) {
            this.cart.deliveryMethod = {
                id: id,
                description: description,
                shortCode: code,
                amount: amount,
            };
            this.updateCart("fusionn.setdeliverymethod");
        }
    }

    updateCartTotals() {

        //console.log("updateCartTotals");
        //console.log(this.cart.items);
        let round = this.config.sitePrices[this.config.sitePrices.country].round;
        let cart = {
            currency: undefined,

            items: [],
            itemQuantity: 0,
            itemAmount: 0,
            itemDiscount: 0,
            itemTaxes: 0,
            itemTotal: 0,

            services: [],
            servicesAmount: 0,
            servicesTaxes: 0,
            servicesTotal: 0,

            discountCode: undefined,

            discountUnit: 'none',
            discountApplyTo: 'none',
            discountPercentage: 0,

            subTotalAmount: 0,
            discountAmount: 0,
            totalAmount: 0,
        };

        if (this.cart.currency === undefined) {
            this.initCartCurrency(window.Moneda, window.opCartMoneda);

        }
        cart.currency = this.cart.currency.code;

        // -----------------------------------------
        // Services
        if (this.cart.deliveryMethod !== undefined) {
            cart.services.push({
                id: this.cart.deliveryMethod.id,
                type: 'delivery',
                price: this.cart.deliveryMethod.amount,
                discount: 0,
                taxes: 0,
                total: this.cart.deliveryMethod.amount
            });
        }
        // .... otros servicios aca ....

        // Totalizo servicios ...
        cart.services.forEach((item) => {
            cart.servicesAmount += item.total;
            cart.servicesTaxes += item.taxes;
            cart.servicesTotal += item.total;
        });

        // Recolecto los productos
        let productTypeCounter = [];
        let productCounter = 0;

        this.cart.items.forEach((item) => {
            productCounter++;

            if (productTypeCounter[item.type] === undefined) {
                productTypeCounter[item.type] = 0;
            }
            productTypeCounter[item.type]++;

            // Actualizar precios de productos
            item.price = this.calc.calc(item.price.price.base, item.price.quantity, true, item.type, productTypeCounter[item.type], productCounter);

            cart.items.push({
                id: item.id,
                type: item.type,
                quantity: item.price.quantity,
                base: item.price.price.base,
                unit: item.price.price.unit,
                price: item.price.price.item,
                discount: item.price.price.discount,
                taxes: item.price.price.taxes,
                total: item.price.price.total
            });
        });

        cart.items.forEach((item) => {
            cart.itemQuantity += item.quantity;
            cart.itemAmount += item.price;
            cart.itemDiscount += item.discount;
            cart.itemTaxes += item.taxes;
            cart.itemTotal += item.total;
        });


        // console.log(cart);

        // -----------------------------------------
        // Calculo descuentos si corresponde!
        if (this.cart.promoCode !== undefined && this.cart.promoCode.code !== undefined) {
            let discount = this.calcDiscount(cart);
            cart.discountCode = this.cart.promoCode.code;
            cart.discountPercentage = discount.percentage;
            cart.discountAmount = discount.amount;
            cart.discountApplyTo = discount.applyTo;
            cart.discountUnit = discount.unit;
        }

        /** descuentos en los items ...
        // Los descuentso de los items ya estan representados en cada elemento, no es neesario representarlo en el sub total
        // if (cart.discountAmount === 0) {
            cart.items.forEach((item) => {
                cart.discountAmount += item.discount;
            });   
        }
        **/

        // -----------------------------------------
        // Totales
        cart.subTotalAmount = parseFloat((cart.servicesTotal + cart.itemTotal).toFixed(round.total));
        cart.totalAmount = parseFloat((cart.subTotalAmount - cart.discountAmount).toFixed(round.total));

        //console.log(cart);

        this.cart.totals = cart;
    }

    refreshCart(from) {
        this.checkCart();
        this.updateCartTotals("fusionn.refreshcart");
    }

    updateCart(form) {
        this.updateSession('cart', 'fusionn.updateCart');
        this.refreshCart("updateCart");
    }

    initCartCurrency(showCurrency, currencyId) {
        this.checkCart();
        this.cart.currency = {
            id: currencyId,
            code: undefined,
            visibleCode: showCurrency,
        };
        this.updateSession('cart', 'fusionn.initCartCurrency');
    }

    calcDiscount(resume) {
        let round = this.config.sitePrices[this.config.sitePrices.country].round;
        let result = {};
        let discount = null;
        if (this.cart.promoCode !== undefined && this.cart.promoCode.promocode !== undefined) {
            discount = this.cart.promoCode.promocode;
        }
        if (discount !== null && discount) {

            let applyTo = 0;
            switch (discount.applyto) {
                case 'items':
                    applyTo = resume.itemTotal;
                    break;
                case 'fotoc':
                    resume.items.forEach((item) => {
                        if (item.type === "fotoc") {
                            applyTo += item.total;
                        }
                    });
                    break;
                case 'etiquetoc':
                    resume.items.forEach((item) => {
                        if (item.type === "etiquetoc") {
                            applyTo += item.total;
                        }
                    });
                    break;
                case 'all':
                default:
                    applyTo = resume.itemTotal + resume.servicesTotal;
                    break;
            }

            let discountValue = (discount.discount !== undefined ? parseFloat(discount.discount) : 0);
            let discountAmount = 0;
            let discountPercentage = 0;

            if (discount.discount_type === 'percentage') {
                discountAmount = parseFloat((discountValue * applyTo / 100).toFixed(round.discount));
                discountPercentage = discountValue;
            } else {
                discountAmount = applyTo < discountValue ? applyTo : discountValue;
                discountPercentage = parseFloat((discountValue * 100 / applyTo).toFixed(round.discount));
            }

            result = {
                apply: true,
                unit: discount.discount_type,
                percentage: discountPercentage,
                amount: discountAmount,
                applyTo: applyTo,
            };
        } else {
            result = {
                apply: false,
                unit: "",
                percentage: 0,
                amount: 0,
                applyTo: 0,
            };
        }
        return result;
    }

    getCartResume() {
        let resume = {
            price: this.cart.totals.totalAmount,
            currency: this.cart.totals.currency,
            compositions: []
        };
        for (let i = 0; i < this.cart.items.length; i++) {
            let item = this.cart.items[i];
            let productResume = this.productType[item.type].getCartResumeItem(
                item.attributes.data,
                item.price.price.total,
                item.attributes.previewFile);
            resume.compositions.push(productResume
            );
        }
        return resume;
    }

    detectAdAccess() {
        // Facebook
    }

    addFavorite(item) {
        this.favorites.push(item)
        this.setConfigItem('favorites', this.favorites);
    }

    removeFavoriteByDataUUID(uuid) {
        if (this.favorites.length) {
            this.favorites = this.favorites.filter((ele) => { if (ele && ele.data && ele.data.uuid !== uuid) { return ele; } });
            this.setConfigItem('favorites', this.favorites);
        }
    }

    listFavorites(filterKey, filterValue) {
        if (this.favorites.length === 0) {
            this.favorites = this.config.getItem('favorites');
        }
        if (filterKey) {
            return this.favorites.map((ele, index) => {
                if (ele && ele[filterKey] && ele[filterKey] === filterValue) {
                    return ele;
                }
            });
        } else {
            return this.favorites;
        }
    }

    hasFavoriteByDataUUID(uuid) {
        if (this.favorites.length) {
            return this.favorites.some((ele) => {
                if (ele && ele.data && ele.data.uuid && ele.data.uuid === uuid) {
                    return true;
                }
            });
        }
        return false;
    }
}

export default Fusionn;