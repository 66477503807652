import React, { Component } from 'react';
import slugify from "slugify";
//import {Link} from "react-router-dom";
/*
<Link to={"/p/poster/show/" + (this.props.media_type ? this.props.media_type : 'movie') + "/" + this.props.id}>
    {
        this.props.poster_path ? <img src={'https://image.tmdb.org/t/p/w220_and_h330_face' + this.props.poster_path} width={20} height={30} /> : ""
    }
    <div>
        <label>{this.props.title}</label>
        <p>{this.props.release_date}</p>
    </div>
</Link>
*/

export default class SearchOption extends Component {
    constructor(props) {
        super(props);
    }

    getCaption(title, alternative, release_date) {
        return (
            <div><label>{ title !== undefined ? title : alternative }</label><p>{ release_date !== undefined ? ({release_date}) : ""}</p></div>
        );
    }

    render() {

        let original_title;
        let title;
        let year;

        if (this.props.media_type === "movie") {
            original_title = this.props.original_title;
            title = this.props.title ? this.props.title : this.props.original_title;
            year = this.props.release_date ? this.props.release_date : "";
        } else {
            original_title = this.props.original_name;
            title = this.props.name ? this.props.name : this.props.original_name;
            year = this.props.first_air_date ? this.props.first_air_date : "";
        }

        return (
            <a href={"/p/poster/show/" + (this.props.media_type ? this.props.media_type : 'movie') + "/" + this.props.id  + '/' + slugify(original_title)} className={this.props.posters ? 'withPosters' : 'withoutPosters'}>
                {
                    this.props.poster_path ? <img src={'https://image.tmdb.org/t/p/w220_and_h330_face' + this.props.poster_path} width={20} height={30} /> : ""
                }
                <div>
                    <label>{title}</label>
                    <p>{year}</p>
                </div>
            </a>
        );
    }
}