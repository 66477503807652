import ItemPrice from '../payment/ItemPrice';
import PreviewStore from './tool/PreviewStore';

class Tiles {
    constructor() {
        this.type = "tiles";
        this.clearForSync = true;
        this.calc = new ItemPrice();
        this.getLayout = this.getLayout.bind(this);
        this.getItemsToCart = this.getItemsToCart.bind(this);
        this.calcItemPrice = this.calcItemPrice.bind(this);
        this.uuid = this.uuid.bind(this);
        this.getCartResumeItem = this.getCartResumeItem.bind(this);
        this.getDataFromEditor = this.getDataFromEditor.bind(this);
    }

    getLayout(id, size) {
        return {
            id: id,
            size: size,
            model: "CF" + id.toUpperCase() /*+ size*/,
            /* model: id.toUpperCase(), */
            pieceNumber: 1,
            sizeComposition: [] /*window.editorConfig.layouts[id].sizes[size].size_group*/,
            pieces: []
        };
    }

    getDataFromEditor() {
        let data = null;
        try {
            data = window.Editor.getData();
            if (parseFloat(data.cost.price.total) === 0) {
                window.Editor.updatePrice();
                data = window.Editor.getData();
            }
        } catch(error) {
            console.error(error);
        }

        if (data === null) {
            return;
        }

        return {data: data};
    }

    getItemsToCart() {
        let item = this.getDataFromEditor();
        if (item.data === null || item.data.images.length === 0) {
            return [];
        }

        let items = [];
        let newData = JSON.parse(JSON.stringify(item.data));

        newData.images = [];
        newData.layout = this.getLayout(newData.layout_id, newData.layout_size);
        newData.info = this.getCartResumeItem(newData, null, undefined);

        for (let x=0; x < item.data.images.length; x++) {
            const id = this.uuid();
            const attributes = {
                data: JSON.parse(JSON.stringify(newData))
            };
            
            attributes.data.material = 'F';
            attributes.data.stand = '';
                        
            // Arreglo las piezas
            attributes.data.layout.pieces = [ {
                id: 1,
                model: attributes.data.layout.model + "1",
                size: window.editorConfig.tiles.data.layouts[attributes.data.layout.id.toString()].sizes[attributes.data.layout.size].sizes[0]
            }];
        
            attributes.data.info = this.getCartResumeItem(attributes.data, null, undefined);    
            
            attributes.data.images.push(JSON.parse(JSON.stringify(item.data.images[x])));
            let price = this.calcItemPrice(newData.cost.price.base, x+1);
            items.push({
                type: this.type,
                price: price,
                quantity: 1,
                plusValia: true,
                attributes: attributes,
                deleted: 0,
                typePosition: x+1,
                id: id
            });
        }
        PreviewStore.buildPreviews(items);
        return items;
    }

    calcItemPrice(priceBase, typePosition, globalPosition) {
        return this.calc.calc(priceBase, 1, true, this.type, typePosition, globalPosition);
    }

    uuid() {
        return ((Math.random()*1e32).toString(36).substring(1,8)).toUpperCase();
    }

    getCartResumeItem(item, price, image) {
        let iResume = {
            model: item.layout.model,
            price: price,
            size: item.layout_size + ' (' + item.layout.sizeComposition.join('x') + ' cm)',
            material: 'Foam board',
            stand: 'Foam board'
        };

        if (image !== undefined) {
            iResume.image = image.url.replace('/f/f/', '/150/-/');
        }

        let pieces = [];
        for (let j = 0; j < item.layout.pieces.length; j++) {
            pieces.push(item.layout.pieces[j].size.join('x'));
        }
        iResume.pieces = pieces.join(' cm / ') + ' cm';            
        
        return iResume;
    }
}

export default Tiles;