import React, { useState, useEffect } from "react";
import {showLoading, hideLoading, gotoTop} from "../../util/SwitchLoading";
import jQuery from 'jquery';
import SimpleGalleryContainerLink from "../../ui/fanatico/poster/SimpleGalleryContainerLink";

const u = jQuery.noConflict();

const hideAll = () => {
    u('#top').hide();
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

export default function FavoritesPage() {
    const [favorites, setFavorites] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    showLoading();
    hideAll();
    u('body').addClass('home');
    u('body').removeClass('producto');
    u('section.top').show();
    u('#info').show();

    useEffect(() => {
        showLoading();
        setIsLoading(false);
        setFavorites(window.FUSIONN.listFavorites(null, null));
    }, [window.FUSIONN.favorites]);

    if (isLoading) {
        return (<React.Fragment></React.Fragment>);
    }

    gotoTop();
    hideLoading();

    if (favorites.length === 0) {
        u('#info').hide();
        u('#favoritesInstructions').show();
        u('#favoritesInstructions').get(0).scrollIntoView();
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <section className={"template_align_right"} >
            <SimpleGalleryContainerLink images={favorites} name={'Mis favoritos'} slug={'favorites'}/>
        </section>
    );
}
