import ConfigSite from "../ConfigSite";
import * as promotionModule from "./promotion/PromotionModule";

class ItemPrice {

    constructor() {
        this.config = new ConfigSite();
    }

    calc(priceBase, quantity, plusValia, productType, typePosition, globalPosition) {

        if (productType === undefined) {
            productType = window.TYPE_EDITOR;
        }

        let localQuote = parseFloat(this.config.sitePrices[this.config.sitePrices.country].currencyQuoteLocal);

        let round = this.config.sitePrices[this.config.sitePrices.country].round;



        let itemPriceConfig = this.config.sitePrices[this.config.sitePrices.country].product[productType].items;
        let itemPromotionConfig = this.config.sitePrices[this.config.sitePrices.country].product[productType].promotion;
        let taxesConfig = this.config.sitePrices[this.config.sitePrices.country].taxes;

        let unitaryBasePrice = priceBase;
        let unitaryPrice = parseFloat((unitaryBasePrice * (plusValia ? itemPriceConfig.multiplier : 1.0) * localQuote).toFixed(round.price));
        let quantityVal = quantity === undefined ? 1 : quantity;
        let priceSchema = {
            taxes: [],
            quantity: quantityVal,
            price: {
                base: unitaryBasePrice,
                unit: unitaryPrice,
                item: unitaryPrice * quantityVal,
                discount: 0,
                discountItemInfo: { has: false },
                taxes: 0,
                subTotal: 0,
                total: 0
            },
            currency: this.config.sitePrices.currency
        };

        let discount = this.calcPromotion(itemPromotionConfig, unitaryPrice * quantityVal, typePosition, globalPosition, round.discount);
        if (discount.has) {
            priceSchema.price.discount = discount.discount;
            priceSchema.price.discountItemInfo = discount;
        }

        // Calc Taxes!
        if (taxesConfig.length) {
            taxesConfig.forEach((it) => {
                priceSchema.taxes.push({
                    name: it.code,
                    value: parseFloat((it.multiplier * (priceSchema.price.item - priceSchema.price.discount)).toFixed(round.taxes))
                });
            });
            let taxesTotal = 0;
            priceSchema.taxes.forEach((it) => {
                taxesTotal += it.value;
            });
            priceSchema.price.taxes = taxesTotal;
        }
        // Calc Total!
        priceSchema.price.total = parseFloat((priceSchema.price.item - priceSchema.price.discount + priceSchema.price.taxes).toFixed(round.total));
        return priceSchema;
    }

    calcPromotion(promotion, itemPrice, typePosition, globalPosition, round) {
        let discount = {};
        if (promotion !== undefined && promotion.active === 1) {
            discount = promotionModule[promotion.type](promotion, itemPrice, typePosition, globalPosition);
            if (discount.discount !== undefined) {
                discount.discount = parseFloat(discount.discount.toFixed(round));
            }
        }
        return discount;
    }

    calcSimulatedCart(itemPrice, elements) {
        let items = [];
        for (let i = 0; i < elements; i++) {
            items.push(this.calc(itemPrice, 1, true, window.TYPE_EDITOR, i + 1, i + 1));
        }
        let priceSchema = { price: { base: 0, total: 0 }, items: items, discountItemInfo: null };
        if (items.length) {
            priceSchema.price.base = items[items.length - 1].price.base;
            priceSchema.price.unit = items[items.length - 1].price.unit;
            if (items[items.length - 1].price.discountItemInfo.has) {
                priceSchema.discountItemInfo = items[items.length - 1].price.discountItemInfo;
            }
            for (let j = 0; j < items.length; j++) {
                priceSchema.price.total += items[j].price.total;
            }
        }
        return priceSchema;
    }


}


export default ItemPrice;