import React, { useState, useEffect } from 'react';
import jQuery from 'jquery';
import {showLoading, hideLoading} from "../../util/SwitchLoading";
import ConfigSite from "../../ConfigSite";
import SimpleGalleryContainerLink from "../../ui/fanatico/poster/SimpleGalleryContainerLink";
import SimpleGalleryContainerClick from "../../ui/fanatico/poster/SimpleGalleryContainerClick";
import axios from "axios";
import { useParams } from 'react-router';
const u = jQuery.noConflict();

const config = new ConfigSite();
const API_POSTER = 'es/cinematheque/poster/';
const API_SUMMARY = 'es/cinematheque/summary/';

const gotoTop = () => {
    window.scrollTo(0, 0);
}

const hideAll = () => {
    u('#top').hide();
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

export default function PosterPage(props) {

    const [poster, setPoster] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    u('body').addClass('producto');
    u('body').removeClass('home');

    useEffect(() => {
        if (props.origin === "poster" ) {
            getPageDataFromPoster();
        } else {
            getPageDataFromMovie();
        }
    }, [params.id_image, params.id_media]);

    function getPageDataFromPoster() {
        showLoading();
        hideAll();

        setPoster(null);
        setIsLoading(true);

        let id_image = params.id_image;

        axios.get(config.global.mediaApi.urlBase + API_POSTER + id_image)
            .then((response) => {
                let data = response.data;
                if (data.selected && data.selected.data) {
                    const dataImage = {...data.selected.data};
                    window.Editor.setImage(dataImage);
                    gotoTop();
                }
                setPoster(data);
                setIsLoading(false);
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
    }

    function getPageDataFromMovie() {
        showLoading();
        hideAll();

        setPoster(null);
        setIsLoading(true);

        let media_type = params.media_type;
        let id_media = params.id_media;

        axios.get(config.global.mediaApi.urlBase + API_SUMMARY + 'portrait/' + media_type + '/' + id_media)
            .then((response) => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' + response.status);
                    return;
                }

                if (response.data.selected && response.data.selected.data) {
                    const dataImage = {...response.data.images[0].data};
                    window.Editor.setImage(dataImage);
                    gotoTop();
                }

                setPoster(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    if (isLoading) {
        return (<React.Fragment></React.Fragment>);
    }

    let title = "";
    if (poster.images.length > 0) {
        title = "Más de " + (poster.selected.data.metadata ? poster.selected.data.metadata.name : " ... ");
        u('#linkAuthor').html(poster.selected.data.publisher.name);
        u('#editorSection').show();
        u('#authorContainer').show();
        u('#cartSection').show();

    } else {
        u('.imgMovieNotFound').attr('src', 'https://image.tmdb.org/t/p/w220_and_h330_face' + poster.film.poster_path );
        u('.linkMovieNotFound').attr('href','https://www.themoviedb.org/' + (poster.film.title ? 'movie' : 'tv') + '/' + poster.film.id + '/images/posters' );
        u('.titleMovieNotFound').html(poster.film.title ? poster.film.title : poster.film.name);
        u('#not-found').show();
        u('#sectionComoFunciona').hide();
    }

    gotoTop();
    hideLoading();

    return (<React.Fragment>
                {
                    poster.images.length > 1 ? (
                        <section className={"template_align_right"} >
                            <SimpleGalleryContainerClick images={poster.images} name={title} slug={"posters"}/>
                        </section>
                    ) : ""
                }
                {
                    poster.images.length > 0 ? (
                        <section className={"como-funciona como-funciona-desktop"} dangerouslySetInnerHTML={{__html: u('#sectionComoFunciona').html()}} />
                    ) : ""
                }

                <section className={"template_align_right"} >
                    <SimpleGalleryContainerLink images={poster.relateds} name={"Relacionados"} slug={"related"}/>
                </section>
            </React.Fragment>);
}
