/*global
  fbq,
  ga,
  Moneda,
  opCartMoneda,
  sitePrices,
  smoothScroll,
  getUrlParameter,
  ManageAd,
  getWindowsSize,
  BrowserDetection,
  ManageFirsWalkthrough,
  walkthroughEval,
  pathStorage,
  templateDataPrice,
  materialDataPrice,
  ShippingLevels,
  ShippingLevelsInput,
  CountryName,
  RequiredFieldsDni,
  RequiredFieldsZipCode,
  RequiredFieldsZipNote,
  RequiredFieldsComment,
  translateText,
  pathBase,
  DomainCode,
  ShippingZipCode,
  ContBuilder,
  ShippingCodeType.
  getZipFrom,
  getOpEnvio,
  RequireApartmentNumber,
  DisplayApartmentNumber,
  DisplayDoorNumber,
  RequireDoorNumber,
  translate,
  cartListStore,
  addToCart,
  removeFromCart,
  initCart,
  createCity,
  evalZipCode,
  closeall
*/
/*eslint no-undef: "error"*/
import React from 'react';
import Fusionn from './app/Fusionn';
import jQuery from 'jquery';
import {createStore} from "redux";
import {connect, Provider} from "react-redux";
import Cart from "./app/ui/payments/Cart";
import ReactDOM from "react-dom";
import PromoCode from "./app/ui/payments/PromoCode";
import ItemPrice from './app/payment/ItemPrice';
import SiteRouter from "./app/site/SiteRouter";
import PosterSearchForm from './app/site/PosterSearchForm';
import ArtSearchForm from './app/site/ArtSearchForm'
import {showLoading, hideLoading} from "./app/util/SwitchLoading";
import reportWebVitals from './reportWebVitals';

let u = jQuery.noConflict();

let FUSIONN = new Fusionn();
window.FUSIONN = FUSIONN;
FUSIONN.setup();

let eleOpEnvio = u("#opEnvio");
let eleClassPriceDiscount = u('.priceDiscount');
let elePromoCodeFormContainer = u('.promoCodeFormContainer');
let elePriceFinal = u('.priceFinal');
let eleOpCartSubtotal = u('#opCartSubtotal');
let eleOpCartShipping = u('#opCartShipping');
let eleOpCartTotal = u('#opCartTotal');
let eleOpCartShippingTypeID = u('#opCartShippingTypeID');
let eleOpCartShippingType = u('#opCartShippingType');
let eleIdEstado = u('#idEstado');
let eleIdCiudad = u('#idCiudad');
let eleCartItemCounter = u('#cartItemCounter');
let eleCartCounterContainer = u('#cartCounterContainer');
let eleBackCartFromMain = u('#backCartFromMain');
let eleIdPromoCodeFormContainer = u('#promoCodeFormContainer');
let eleBackCreator = u("#backCreator");
let eleOpEstado = u("#opEstado");
let eleIdDepartamento = u("#idDepartamento");
let eleOpCiudad = u("#opCiudad");
let eleOpBarrio = u("#opBarrio");
let eleCart = u("#cart");
let eleStepOrder = u("#step_order");
let eleSiteGallery = u("#homeSiteSections");
let eleStepCheckout = u("#step_checkout");
let eleStepEndCheckout = u("#step_endcheckout");
let eleCartSection = u("#cartSection");
let eleBackCart = u("#backCart");
let eleOpItAGift = u("#opIsAGift");
let eleGiftStyle = u("#giftStyle");
let eleGiftNameControl = u("#giftNameControl");
let eleGiftDes = u("#giftDescriptionControl");
let eleGiftName = u("#opGiftName");
let eleGiftDescription = u("#opGiftDescription");
let eleStepBuilder = u('#step_builder');
let eleShipLevel0 = u("#shipLevel0");
let eleShipLevel1 = u("#shipLevel1");
let eleShipLevel2 = u("#shipLevel2");
let eleOpPais = u("#opPais");
let eleOpDNI = u("#opDNI");
let eleFormDni = u("#formDni");
let eleOpCP = u("#opCP");
let eleFormZipCode = u("#formZipCode");
let eleTxFormZipNote = u("#txFormZipNote");
let eleOpComentario = u("#opComentario");
let eleFormComment = u("#formComment");
let eleUploadButtons = u(".upload-button");
let btnGuardar = u('#guardar_button');

window.lastTextSearch = "";

// Agregar al carro
window.handleAddToCart = () => {
    if (FUSIONN.addToCart(window.TYPE_EDITOR)) {
        window.refreshCart();
        if (typeof(fbq) !== 'undefined') {
            fbq('track', 'AddToCart');
        }
        if (typeof(ga) !== 'undefined') {
            ga('send', 'event', 'button', 'click', 'add to cart');
        }
        return true;
    } else {
        return false;
    }
};

window.refreshCart = () => {
    cartListStore.dispatch(addToCart(FUSIONN.cart.items));
    updateCartCounter();
};


window.handleRemoveFromCart = id => {
    FUSIONN.removeFromCart(id);
    cartListStore.dispatch(removeFromCart(id));
    updateCartCounter();
    if (FUSIONN.cart.items.length === 0) {
        viewCreator();
    }
};

let updateCartCounter = () => {
    cuentasFinales();
    let cartCounter = FUSIONN.getCartItemCount();
    if (cartCounter === 0) {
        elementHide(eleCartCounterContainer);
    } else {
        eleCartItemCounter.html(cartCounter.toString());
        elementShow(eleCartCounterContainer);
    }
};

window.initCartView = () => {
    if (FUSIONN.cart.currency === undefined) {
        FUSIONN.initCartCurrency(Moneda, opCartMoneda);
    }
    updateCartCounter();
    if (cartListStore !== undefined) {
        cartListStore.dispatch(initCart(FUSIONN.cart));
    }

    if (window.Editor !== undefined && window.Editor !== null) {
        window.Editor.updatePrice();
    }

    console.log("init cart...");
console.log(btnGuardar);
    btnGuardar.removeAttr('disabled');
    
    elementShow(eleIdPromoCodeFormContainer);
};

let elementIsVisible = (ele) => {
    if (!ele.length) return false;
    return ele.is(":visible");
};

let hideElementIfVisible = (ele, speed) => {
    if (elementIsVisible(ele)) {
        ele.slideUp(speed);
    }
};

let showElementIfInVisible = (ele, speed, func) => {
    if (!elementIsVisible(ele)) {
        ele.slideDown(speed, func);
    }
};

let viewCreator = () => {
    if (FUSIONN.cart.items.length) {
        elementHide(eleBackCartFromMain);
    }

    hideElementIfVisible(eleStepOrder, "slow");
    hideElementIfVisible(eleStepCheckout, "slow");
    hideElementIfVisible(eleStepEndCheckout, "slow");
    showElementIfInVisible(eleStepBuilder, "slow");
    showElementIfInVisible(eleSiteGallery, "slow");
    showElementIfInVisible(eleUploadButtons, "slow");

    smoothScroll.animateScroll("#top");

};

let viewCart = () => {

    if (typeof closeall === "function") {
        closeall();
    }

    if (eleCartSection.length) {
        eleCartSection.show();
    }

    hideElementIfVisible(eleUploadButtons, "slow");
    hideElementIfVisible(eleStepBuilder, "slow");
    hideElementIfVisible(eleSiteGallery, "slow");
    hideElementIfVisible(eleStepCheckout, "slow");
    hideElementIfVisible(eleStepEndCheckout, "slow");
    elementShow(eleBackCartFromMain);
    showElementIfInVisible(eleStepOrder, "slow");
    smoothScroll.animateScroll("#top");
};

let elementShow = (ele) => {
    if (ele.length) {
        ele.show();
    }
};

let elementHide = (ele) => {
    if (ele.length) {
        ele.hide();
    }
};

let cuentasFinales = function () {
    let MetodoEnvioData = eleOpEnvio.length && eleOpEnvio.val() ? eleOpEnvio.val().split("|") : [0, ""];
    FUSIONN.setDeliveryMethod(parseInt(MetodoEnvioData[0]), u("#opEnvio option:selected").text(), MetodoEnvioData[2], parseFloat(MetodoEnvioData[1]));
    FUSIONN.updateCartTotals("cuentasFinales");
    FUSIONN.updateSession("cart", "cuentasFinales");
    let cart = FUSIONN.getCart();
    if (cart.totals.discountUnit !== 'none' && cart.totals.discountUnit !== '') {
        let mostrar = cart.currency.visibleCode + '-' + cart.totals.discountAmount;
        if (cart.totals.discountUnit === 'percentage') {
            mostrar += ' (' + cart.totals.discountPercentage + '%)';
        }
        eleClassPriceDiscount.html(mostrar);
        elePromoCodeFormContainer.show();
    } else {
        elePromoCodeFormContainer.hide();
    }
    elePriceFinal.html(cart.currency.visibleCode + cart.totals.totalAmount);
    eleOpCartSubtotal.val(cart.totals.itemTotal);
    eleOpCartShipping.val(cart.totals.servicesTotal);
    eleOpCartTotal.val(cart.totals.totalAmount);
    eleOpCartShippingTypeID.val(cart.deliveryMethod.id);
    eleOpCartShippingType.val(cart.deliveryMethod.shortCode);
};
window.cuentasFinales = cuentasFinales;

// ---------------------------------------------------------------------------------------------------------------------
//  DOCUMENT READY
u(() => {

    eleBackCreator.on('click', viewCreator);

    eleCartCounterContainer.on('click', () => {
        viewCart();
    });

    // BOTONES STEPS
    u(document).on('click', '#guardar_button', () => {
        if (window.handleAddToCart()) {
            viewCart();
        }
    });

    eleCart.on('click', () => {
        showLoading();
        eleStepOrder.slideToggle();
        eleStepCheckout.slideToggle();
        eleOpEstado.trigger('change');
        smoothScroll.animateScroll("#top");

        if (typeof(fbq) !== 'undefined') {
            fbq('track', 'InitiateCheckout');
        }
        if (typeof(ga) !== 'undefined') {
            ga('send', 'event', 'button', 'click', 'checkout');
        }
        hideLoading();
    });

    eleBackCartFromMain.on('click', () => {
        eleStepBuilder.slideToggle();
        eleStepOrder.slideToggle();
        smoothScroll.animateScroll("#top");
    });

    eleBackCart.on('click', () => {
        eleStepOrder.slideToggle();
        eleStepCheckout.slideToggle();
        smoothScroll.animateScroll('#top');
    });

    u("#opIsAGift").on("click", function () {
        if (u("#opIsAGift").is(":checked")) {
            u("#giftStyle").show();
            u("#giftNameControl").show();
            u("#giftDescriptionControl").show();
            u("#opGiftName").attr("required", "required");
            u("#opGiftDescription").attr("required", "required");
        } else {
            u("#giftStyle").hide();
            u("#giftNameControl").hide();
            u("#giftDescriptionControl").hide();
            u("#opGiftName").removeAttr("required");
            u("#opGiftDescription").removeAttr("required");
        }
    });

    btnGuardar.removeAttr('disabled');

});


/*********************************************************************************************************************
 * Actions
 */
export const initCart = cart => ({
    type: 'INIT_CART',
    cart,
});

export const addToCart = items => ({
    type: 'ADD_TO_CART',
    items,
});

export const removeFromCart = id => ({
    type: 'REMOVE_FROM_CART',
    id,
});

export const promoCodeApply = promoCode => ({
    type: 'APPLY_PROMO_CODE',
    promoCode,
});

export const promoCodeRemove = () => ({
    type: 'REMOVE_PROMO_CODE',
});

/*********************************************************************************************************************
 * Reducers!!
 */

export const cartListReducer = (state = {}, action) => {
    let newState = {...state};
    switch (action.type) {
        case 'INIT_CART':
            if (action.currency !== undefined) {
                newState.currency = action.currency
            } else {
                newState.currency = {};
            }
            newState.items = [...action.cart.items];
            if (action.cart.promoCode !== undefined) {
                newState.promoCode = action.cart.promoCode;
            } else {
                newState.promoCode = {};
            }
            break;
        case 'ADD_TO_CART':
            newState.items = [...action.items];
            /*if (action.promoCode !== undefined) {
            newState.promoCode = action.promoCode;
            } else {
            newState.promoCode = {};
            }*/
            break;
        case 'REMOVE_FROM_CART':
            newState.items = newState.items.filter(item => item.id !== action.id);
            break;
        case 'APPLY_PROMO_CODE':
            if (action.promoCode !== undefined) {
                newState.promoCode = action.promoCode;
            } else {
                newState.promoCode = {};
            }
            break;
        case 'REMOVE_PROMO_CODE':
            newState.promoCode = {};
            break;
        default:
            break;
    }
    return newState;
};

export function configureStore(initialState = {}) {
    return createStore(cartListReducer, initialState);
}

let cartListStore = configureStore();
if (FUSIONN.cart !== undefined && FUSIONN.cart.items !== undefined) {
    cartListStore.dispatch(initCart(FUSIONN.cart));
}

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = {
    promoCodeApply,
    promoCodeRemove
};

const CartListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Cart);

if (document.getElementById('cartOrder') !== null) {

    ReactDOM.render(
        <Provider store={cartListStore}>
            <CartListContainer/>
        </Provider>,
        document.getElementById('cartOrder')
    );
}
const PromoCodeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoCode);

if (document.getElementById('promoCodeFormContainer') !== null) {

    ReactDOM.render(
        <Provider store={cartListStore}>
            <PromoCodeContainer/>
        </Provider>,
        document.getElementById('promoCodeFormContainer')
    );
}


// Contenedor de ruteo del sitio! :O)
if (document.getElementById('homeSiteSections')) {
    ReactDOM.render(
        <SiteRouter/>,
        document.getElementById('homeSiteSections')
    );
}


/*
// Render shipping module
import React from 'react';
import Shipping from './app/shipping/Shipping.js';
ReactDOM.render(<Shipping />, document.getElementById('fusionnShippingForm'));
*/

/*
import Cart from './app/payments/Cart.js';
function App(){
    return(
        <Cart />
    )
}
ReactDOM.render(
    <App />,
    document.getElementById('cartOrder')
);
*/

/*******************************
 * Formulario de busqueda para Posters/Art
 */
if (document.getElementById('containerSearchForm') !== null &&
    document.getElementById('containerSearchMobile') !== null
) {
    switch(window.TYPE_EDITOR) {
        case "poster":
            ReactDOM.render(
                <PosterSearchForm/>,
                document.getElementById('containerSearchForm')
            );
        
            ReactDOM.render(
                <PosterSearchForm/>,
                document.getElementById('containerSearchMobile')
            );        
        break;
        case "art": 
            ReactDOM.render(
                <ArtSearchForm/>,
                document.getElementById('containerSearchForm')
            );
        
            ReactDOM.render(
                <ArtSearchForm/>,
                document.getElementById('containerSearchMobile')
            );        
        break;
    }
}

/**********************************************************************************************************************
 * Comunicacion
 */
// TODO Deberia de recibir el tipo de producto de momento se va decidir por el tipo de editor activo!
window.calcItemPrice = (priceBase, quantity, plusValia) => {
    let price = new ItemPrice();
    return price.calc(priceBase, quantity, plusValia, window.TYPE_EDITOR);
};

window.calcSimulatedCart = (priceBase, elements) => {
    let price = new ItemPrice();
    return price.calcSimulatedCart(priceBase, elements, window.TYPE_EDITOR);
};


/*********************************************************************************************************************
 * Update Storage
 */

let updateStorage = false;
window.onstorage = event => {
    let checkCart = (event.key === 'checkCart' ? (window.localStorage.getItem("checkCart") === "test") : false);
    if (checkCart) {
        window.localStorage.setItem("checkCart", "n/a");
        FUSIONN.setup();
    }

    if ((event.key === 'cart' || checkCart) && !updateStorage) {
        updateStorage = true;
        FUSIONN.needUpdateSession = false;
        FUSIONN.refreshCart("onstorage");
        updateCartCounter();
        FUSIONN.needUpdateSession = true;
        if (cartListStore !== undefined) {
            cartListStore.dispatch(initCart(FUSIONN.cart));
        }
        updateStorage = false;
    }
};



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
