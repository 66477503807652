import React, { Component } from 'react';
import {Link} from "react-router-dom";
import LazyLoad from 'react-lazyload';
import slugify from "slugify";



export default class SimpleGalleryItem extends Component {

    constructor(props) {
        super(props);
        this.item = null;
        this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
    }

    handleFavoriteClick(e) {
        e.preventDefault();
        if (e.target.classList.contains('selected')) {
            window.FUSIONN.removeFavoriteByDataUUID(this.item.data.uuid);
            e.target.classList.remove('selected');
        } else {
            e.target.classList.add('selected');
            window.FUSIONN.addFavorite(this.item);
        }
    }

    render() {
        let item = this.props.item;
        this.item = item;
        return (
            <div className="image-flikity-item">
                <LazyLoad height={334} once={true} offset={500} placeholder={(<img src={'/im/fotoc/icons/icon-image-load-220-330.png'} />)} >
                    <a href="#" className="icon">
                        <i className={"fas fa-heart " + (window.FUSIONN.hasFavoriteByDataUUID(item.data.uuid) ? 'selected' : '') } onClick={this.handleFavoriteClick} >&nbsp;</i>
                    </a>
                    <Link to={"/p/poster/view/poster/" + item.data.imageId  + '/' + slugify(item.data.metadata.original_name, {
                        replacement: '-',
                        lower: true,
                        strict: true,
                        locale: 'es'
                    })}>
                        <img src={item.src} />
                        <div className={"posterItemInfo"}>
                        {
                            item.data.metadata.name ? (
                                <div className='poster-swipper-title' >
                                    {item.data.metadata.name}
                                </div>
                            ) : ''
                        }
                        {
                            item.data.metadata.year ? (
                                <div className='poster-swipper-year' >
                                    ({item.data.metadata.year})
                                </div>
                            ) : ''
                        }
                        </div>
                    </Link>
                </LazyLoad>
            </div>
        );
    }
}
