import React from "react";
import PosterRouter from "./PosterRouter";
import ArtRouter from "./ArtRouter";

export default function SiteRouter() {

    // Debe cambiar según el sitio al que estamos accediendo
    switch(window.TYPE_EDITOR) {
        case "poster":
            return (
                <PosterRouter />
            );      
        case "art": 
            return (
                <ArtRouter />
            );
    }
    
}
