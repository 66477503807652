import React from 'react';
import {i18n} from "../../util/i18n";

let getCartPreviewImage = (sin, attributes) => {
    if (window.FusionnImagePreviews !== undefined && window.FusionnImagePreviews[sin]) {
        return window.FusionnImagePreviews[sin];
    }

    if (attributes !== undefined && attributes.previewFile !== undefined) {
        return '/content/multimedia/fotoc/150/-/' + attributes.previewFile.name
    } else {
        return '/im/im_not_found_es.png';
    }
};

let getCartDescription = (props) => {
    return (
        <div className="descripcion">
            {i18n("TX_PRODUCT_MODEL")}: <strong>{props.item.attributes.data.info.model}</strong><br/>
            {i18n("TX_PRODUCT_SIZE")}: <strong>{props.item.attributes.data.info.size}</strong><br/>
            {i18n("TX_PRODUCT_PIECES")}: <strong>{props.item.attributes.data.info.pieces}</strong><br/>
            {i18n("TX_PRODUCT_MATERIAL")}: <strong>{props.item.attributes.data.info.material}</strong><br/>
            {i18n("TX_PRODUCT_PRICE")}: <strong>{props.currency.visibleCode} {props.item.price.price.total}</strong><br/>
        </div>);
};

const CartElementFotoc = (props) => {
    return (
        <div id={props.item.id}>
            <div className="option">
                <div className="cartContainterImagePreview">
                    <div style={{display: 'none'}} id={`cartContainterImageFB${props.item.id}`}>
                        <img src={getCartPreviewImage(props.item.id, props.item.attributes)}/>
                    </div>
                    <img src={getCartPreviewImage(props.item.id, props.item.attributes)} className="cartImagePreview"/>
                </div>
                {getCartDescription(props)}
                <div className="acciones">
                    <a className="view fancybox" href={`#cartContainterImageFB${props.item.id}`}>
                        <i className="fa fa-eye fa-2x"/>
                    </a>
                    <a href="#remove" className="view" onClick={() => props.removeFromCart(props.item.id)}>
                        <i className="fa fa-times-circle fa-2x"/></a>
                </div>
            </div>
        </div>
    );
};


class Cart extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.items !== undefined && this.props.items.map((item, idx) => (
                        <CartElementFotoc item={item} currency={window.FUSIONN.cart.currency}
                                          removeFromCart={id => window.handleRemoveFromCart(id)} key={item.id}/>
                    ))
                }
            </React.Fragment>
        );
    }
}

export default Cart;