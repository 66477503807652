import React, { Component } from 'react';
import SimpleGalleryContainer from "./SimpleGalleryContainer";

export default class SimpleGalleryContainers extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){

    }

    render() {
        const {sliders} = this.props;
        return  (
            <React.Fragment>
            {
                sliders.length ? sliders.map((slider, i) => (<SimpleGalleryContainer key={'sgc'+i} {...slider} />)) : ""
            }
            </React.Fragment>
        );
    }
}
