import React from 'react';
import {i18n} from "../../util/i18n";
import axios from "axios";

const PromoCodeForm = (props) => {
    return (
        <React.Fragment>
            {props.show === 'newCode' && (
                <a href="#" className="btnShowInputPromoCode" id="btnShowInputPromoCode" onClick={ (e) => {
                    e.nativeEvent.stopImmediatePropagation();
                    e.preventDefault();
                    props.handleBtnShowInputPromoCode();
                    return false;
                }}>
                    <i className="fa fa-plus-circle"></i><span>{i18n("TX_PROMO_TEXT")}</span>
                </a>
            )}
            {props.show === 'searchForm' && (
            <div className="asignar-cupon success" >
                <div id="verificar" >
                    <div>
                        <label>{i18n("TX_PROMO_CODIGO")}</label>
                        <input type="text" id="opCode" />
                    </div>
                    <div>
                    <input type="button" className="boton_principal small" value={i18n("TX_PROMO_VERIFICACION")} onClick={props.handleCheckPromoCode}/>
                    <input type="button" className="boton_principal small" value={i18n("TX_PROMO_CANCEL")} onClick={props.handleBtnCancelInputPromoCode} />
                    </div>
                </div>
                <div className={props.showError ? 'error' : 'hidden'}>
                    <p>{i18n("TX_PROMO_ALERT_ERROR")}</p>
                </div>
            </div>
            )}

        </React.Fragment>
    );
};

const PromoCodeShow = (props) => {
    return (
        <React.Fragment>
            <div className="titleDesc success">
                {i18n("TX_PROMO_NAME")} : <span>{props.promoCode.code.toUpperCase()}</span><br/>
                {i18n("TX_PROMO_DISCOUNT")}
                { props.promoCode.promocode.discount_type === 'percentage' ?
                    " " + props.promoCode.promocode.discount + '%'
                 :
                    " " + ((props.prices !== undefined && props.prices.currency !== undefined) ? props.prices.currency.code : '$' ) + ' ' + props.promoCode.promocode.discount
                }
            </div>

            <a href="#" className="btnRemovePromoCode" onClick={props.handleBtnRemovePromoCode} >
                <i className="fa fa-minus-circle"></i><span>{i18n("TX_PROMO_REMOVE")}</span>
            </a>
        </React.Fragment>
    );
};


class PromoCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promoCodeShow : {
                visible:  'newCode'
            },
            errorSearch: false
        };

        this.handleBtnShowInputPromoCode = this.handleBtnShowInputPromoCode.bind(this);
        this.handleBtnCancelInputPromoCode = this.handleBtnCancelInputPromoCode.bind(this);
        this.handleCheckPromoCode = this.handleCheckPromoCode.bind(this);
        this.handleBtnRemovePromoCode = this.handleBtnRemovePromoCode.bind(this);
    }

    handleBtnRemovePromoCode() {
        this.setState({ promoCodeShow : {visible: 'newCode'} })
        window.FUSIONN.setPromoCode({});
        window.cuentasFinales();
        this.props.promoCodeRemove();
        return false;
    }

    handleBtnShowInputPromoCode() {
        this.setState({ promoCodeShow : {visible: 'searchForm'} })
        return false;
    }

    handleBtnCancelInputPromoCode() {
        this.setState({ promoCodeShow : {visible: 'newCode'} })
        return false;
    }

    handleCheckPromoCode() {

        axios.get('/rest/checkPromoCode/' + document.querySelector('#opCode').value)
            .then((response) => {
                let data = response.data;
                if (data.status !== undefined) {
                    if (data.status === 'ok') {
                        this.setState({errorSearch: false});
                        this.props.promoCodeApply(data.code);
                        window.FUSIONN.setPromoCode(data.code);
                        window.cuentasFinales();
                    } else {
                        this.setState({errorSearch: true});
                    }
                }
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });

        return false;
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.promoCode !== undefined && this.props.promoCode.code !== undefined ? (
                        <PromoCodeShow {...this.props}
                                       handleBtnRemovePromoCode={this.handleBtnRemovePromoCode} />
                    ) : (
                        <PromoCodeForm {...this.props}
                                       handleBtnShowInputPromoCode={this.handleBtnShowInputPromoCode}
                                       handleBtnCancelInputPromoCode={this.handleBtnCancelInputPromoCode}
                                       show={this.state.promoCodeShow.visible}
                                       showError={this.state.errorSearch}
                                       handleCheckPromoCode={this.handleCheckPromoCode}/>
                    )
                }
            </React.Fragment>
        );
    }
}

export default PromoCode;