import React, { useState, useEffect } from 'react';
import jQuery from 'jquery';
import { showLoading, hideLoading } from "../../util/SwitchLoading";
import ConfigSite from "../../ConfigSite";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from 'react-router';
const u = jQuery.noConflict();

const config = new ConfigSite();
const API_PICTURE = 'site/v1/art/';

const gotoTop = () => {
    window.scrollTo(0, 0);
}

const hideAll = () => {
    u('#top').hide();
    u('#editorSection').hide();
    u('#authorContainer').hide();
    u('#cartSection').hide();
    u('#not-found').hide();
    u('#favoritesInstructions').hide();
    u('#btnAddToCartContainer').hide();
}

export default function PicturePage(props) {

    const [picture, setPicture] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    u('#editorSection').show();
    u('#authorContainer').show();
    u('#cartSection').show();

    useEffect(() => {
        getPageDataFromPoster();
    }, [params.id_image, params.gallery]);

    function getPageDataFromPoster() {
        showLoading();
        hideAll();

        setPicture(null);
        setIsLoading(true);

        let id_image = params.id_image;
        let gallery = params.gallery;

        axios.get(config.global.pictureApi.urlBase + API_PICTURE + gallery + '/picture/' + id_image)
            .then((response) => {
                console.log(response.data);
                let data = response.data;
                if (data.picture) {
                    const dataImage = { ...data.picture };

                    /*
 "src": "https:\/\/media.fotoc.com.uy\/images\/portrait_slide\/bf3fb07c8f2a5285c3eb85fbe90c9bea.jpg",
        "width": 2.5,
        "height": 4,
        "data": {
            "fotocOwner": true,
            "uuid": "fotoc-poster-44107",
            "imageId": 44107,
            "movieId": "354912",
            "media_type": "movie",
            "cdnUrl": "https:\/\/media.fotoc.com.uy\/images\/portrait_editor\/bf3fb07c8f2a5285c3eb85fbe90c9bea.jpg",
            "originalUrl": "https:\/\/media.fotoc.com.uy\/images\/original\/bf3fb07c8f2a5285c3eb85fbe90c9bea.jpg",
            "originalImageInfo": {
                "width": 2500,
                "height": 4000
            },
            "galleryPosition": 5,
            "metadata": {
                "name": "Coco",
                "date": "2017-10-27",
                "description": "Miguel es un joven con el sue\u00f1o de convertirse en leyenda de la m\u00fasica a pesar de la prohibici\u00f3n de su familia. Su pasi\u00f3n le llevar\u00e1 a adentrarse en la \"Tierra de los Muertos\" para conocer su verdadero legado familiar.",
                "language": "es",
                "original_name": "Coco",
                "original_language": "en",
                "year": "2017"
            },
            "publisher": {
                "id": "1",
                "name": "steven"
            }
        }
                    */

                    window.Editor.setImage(dataImage);
                    gotoTop();
                }
                setPicture(data);
                setIsLoading(false);
            })
            .catch((error) => {
                // handle error
                console.log(error);
            });
    }

    if (isLoading) {
        return (<React.Fragment></React.Fragment>);
    }

    gotoTop();
    hideLoading();
    u('#top').show();
    u('#editorSection').show();

    return (<React.Fragment>
        {
            picture.images.length > 1 ? (
                <section className={"template_align_right"} >
                    <ImageList variant="masonry" cols={3} gap={10}>
                        {picture.images.map((item) => (
                            <Link to={"/p/art/view/" + params.name + "/" + item.id} key={"link" + item.id}>

                                <ImageListItem key={item.id}>
                                    <img
                                        srcSet={`${item.cdnUrl}`}
                                        src={`${item.cdnUrl}`}
                                        alt={item.description}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            </Link>
                        ))}
                    </ImageList>
                </section>
            ) : ""
        }
        {
            picture.images.length > 0 ? (
                <section className={"como-funciona como-funciona-desktop"} dangerouslySetInnerHTML={{ __html: u('#sectionComoFunciona').html() }} />
            ) : ""
        }
    </React.Fragment>);
}
